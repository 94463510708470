/* Inconsolata */

/* inconsolata-regular - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/inconsolata-v16-latin-regular.a1b73ae2.eot); /* IE9 Compat Modes */
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
       url(/static/media/inconsolata-v16-latin-regular.a1b73ae2.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/inconsolata-v16-latin-regular.e41ccb14.woff2) format('woff2'), 
       url(/static/media/inconsolata-v16-latin-regular.626c21a5.woff) format('woff'), 
       url(/static/media/inconsolata-v16-latin-regular.5727034e.ttf) format('truetype'), 
       url(/static/media/inconsolata-v16-latin-regular.4113edbe.svg#Inconsolata) format('svg'); /* Legacy iOS */
}
/* inconsolata-700 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/inconsolata-v16-latin-700.9d0b91e5.eot); /* IE9 Compat Modes */
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'),
       url(/static/media/inconsolata-v16-latin-700.9d0b91e5.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/inconsolata-v16-latin-700.927962c3.woff2) format('woff2'), 
       url(/static/media/inconsolata-v16-latin-700.299a2b67.woff) format('woff'), 
       url(/static/media/inconsolata-v16-latin-700.288a9673.ttf) format('truetype'), 
       url(/static/media/inconsolata-v16-latin-700.3d148dae.svg#Inconsolata) format('svg'); /* Legacy iOS */
}

/* Viga */

/* viga-regular - latin */
@font-face {
  font-family: 'Viga';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/viga-v6-latin-regular.aab07454.eot); /* IE9 Compat Modes */
  src: local('Viga-Regular'),
       url(/static/media/viga-v6-latin-regular.aab07454.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/viga-v6-latin-regular.5884a0b4.woff2) format('woff2'), 
       url(/static/media/viga-v6-latin-regular.c990d0e5.woff) format('woff'), 
       url(/static/media/viga-v6-latin-regular.34d463ec.ttf) format('truetype'), 
       url(/static/media/viga-v6-latin-regular.2ab676d1.svg#Viga) format('svg'); /* Legacy iOS */
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
.container {
  width: 960px;
  margin: 0 auto;
  height: auto;
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  -webkit-flex-direction: row;
          flex-direction: row;
}
p {
  font-family: 'Inconsolata';
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 0;
}
.main-headings {
  color: #17252A;
  font-size: 58px;
  text-transform: uppercase;
  font-family: 'Viga';
}
.header {
  position: relative;
  width: 100%;
  height: -o-calc(100vh - 50px);
  height: calc(100vh - 50px);
  background: #3AAFA9;
  min-height: 550px;
  margin-bottom: 250px;
}
.header .header-container {
  height: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  bottom: 0;
}
.header .header-container .header-line {
  position: absolute;
  left: 20px;
  bottom: -289px;
  width: 4px;
  height: 0;
  opacity: 0;
  background: #17252A;
  border-radius: 7px;
  transition: all 1s ease-in-out;
}
.header .header-container .header-line:before {
  content: "about me";
  font-family: 'Inconsolata';
  font-weight: bold;
  font-size: 20px;
  color: #17252A;
  position: absolute;
  top: -70px;
  white-space: nowrap;
  left: -36px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 0;
  transition: all 0.5s ease-in-out 1.2s;
}
.header .header-container .header-line:after {
  content: "";
  width: 45px;
  height: 47px;
  position: absolute;
  left: 50%;
  bottom: -95px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: url(/static/media/cross.53f294d9.svg) no-repeat center center;
  background-size: cover;
}
@media (max-width: 980px) {
  .header .header-container .header-line {
    left: 40px;
    height: 0;
    bottom: -100px;
  }
}
.header .header-container .header-line.animate {
  height: 530px;
  opacity: 1;
}
@media (max-width: 980px) {
  .header .header-container .header-line.animate {
    height: 350px;
  }
}
.header .header-container .header-line.animate:before {
  opacity: 1;
}
.header .headerContent {
  position: relative;
  width: 100%;
  height: 100px;
}
.header .headerContent .headerLogo {
  font-family: 'Viga';
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 35px;
  text-decoration: none;
}
.header .headerContent .headerNavigation {
  position: absolute;
  top: 50%;
  right: 135px;
  z-index: 99;
  height: 48px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.header .headerContent .headerNavigation a {
  text-decoration: none;
}
.header .headerContent .headerNavigation a li {
  padding: 0 13px;
  line-height: 48px;
  float: left;
  font-family: 'Inconsolata';
  transition: all 0.3s cubic-bezier(0.22, 0.16, 1, 0.76);
  font-size: 16px;
  font-weight: bold;
  color: #17252A;
  position: relative;
}
.header .headerContent .headerNavigation a li:after {
  content: "";
  width: 0%;
  height: 4px;
  background: #fff;
  position: absolute;
  left: 50%;
  border-radius: 7px;
  bottom: 0px;
  transition: all 0.3s cubic-bezier(0.22, 0.16, 1, 0.76);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.header .headerContent .headerNavigation a li:hover {
  color: #fff;
}
.header .headerContent .headerNavigation a li:hover:after {
  width: 60%;
}
.header .headerHello {
  font-family: 'Inconsolata';
  font-weight: bold;
  font-size: 10rem;
  line-height: 11rem;
  color: #ffffff;
  position: absolute;
  bottom: 90px;
  right: -100px;
}
@media (max-width: 1200px) {
  .header .headerHello {
    right: 20px;
  }
}
@media (max-width: 890px) {
  .header .headerHello {
    bottom: 10px;
    font-size: 100px;
  }
}
@media (max-width: 500px) {
  .header .headerHello {
    font-size: 65px;
  }
}
section {
  width: 100%;
  position: relative;
  height: auto;
}
#speak-code {
  position: relative;
  padding-bottom: 70px;
}
#speak-code .speak-line {
  width: 4px;
  height: -o-calc(100% - 222px);
  height: calc(100% - 222px);
  position: absolute;
  top: 222px;
  border-radius: 7px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #17252A;
}
#speak-code .main-headings {
  width: 100%;
  text-align: right;
}
#speak-code .speak-bio {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 40px;
}
#speak-code .speak-bio p {
  -webkit-flex-basis: 340px;
          flex-basis: 340px;
  position: relative;
}
#speak-code .speak-bio p:before {
  content: "";
  width: 100px;
  position: absolute;
  height: 4px;
  border-radius: 7px;
  bottom: 4px;
  left: -120px;
  background: #17252A;
}
#speak-code .skills {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  margin-top: 60px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
#speak-code .skills .skill-item {
  -webkit-flex-basis: 422px;
          flex-basis: 422px;
  margin-bottom: 20px;
}
#speak-code .skills .skill-item .skill-heading {
  font-family: 'Inconsolata';
  font-weight: bold;
  font-size: 16px;
  color: #17252A;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#speak-code .skills .skill-item .skill-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
#speak-code .skills .skill-item .skill-details .skill-bar {
  height: 10px;
  border-radius: 30px;
  background: #E3EAF1;
  width: 100%;
  position: relative;
}
#speak-code .skills .skill-item .skill-details .skill-bar .skill-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  background: #3AAFA9;
  border-radius: 30px;
  width: 0%;
  transition: all 0.7s ease-in-out 0.3s;
}
#speak-code .skills .skill-item .skill-details .skill-percentage {
  font-family: 'Viga';
  font-weight: 400;
  font-size: 28px;
  color: #17252A;
  margin-left: 10px;
  text-align: center;
}
@media (max-width: 980px) {
  #speak-code .main-headings {
    padding: 0 !important;
    text-align: center;
  }
  #speak-code .speak-line {
    display: none;
  }
  #speak-code .skill-item {
    -webkit-flex-basis: 100% !important;
            flex-basis: 100% !important;
  }
  #speak-code .skill-item .skill-bar {
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  #speak-code .speak-bio p {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    text-align: center;
  }
  #speak-code .speak-bio p:before {
    display: none;
  }
}
#journey {
  padding: 70px 0;
  margin-bottom: 85px;
}
#journey .journey-line {
  width: 4px;
  background: #17252A;
  border-radius: 7px;
  position: absolute;
  top: -2px;
  height: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#journey .journey-line:after {
  content: '';
  width: 45px;
  height: 47px;
  background: url(/static/media/cross.53f294d9.svg) no-repeat center center;
  background-size: cover;
  position: absolute;
  bottom: -85px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#journey .main-headings {
  margin-bottom: 40px;
  text-indent: 50px;
}
#journey .experience {
  -webkit-flex-direction: column;
          flex-direction: column;
}
#journey .experience .experience-item {
  margin-bottom: 90px;
  padding-right: 54%;
  text-align: right;
  position: relative;
  opacity: 0;
  transition: all 0.7s ease-in-out;
}
#journey .experience .experience-item:last-child {
  margin-bottom: 0;
}
#journey .experience .experience-item .experience-dot {
  width: 21px;
  height: 21px;
  border-radius: 21px;
  background: #3AAFA9;
  position: absolute;
  opacity: 1;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 0;
}
#journey .experience .experience-item.right {
  text-align: left;
  padding-right: 0;
  padding-left: 54%;
}
#journey .experience .experience-item .experience-timeline {
  width: 100%;
  font-family: 'Viga';
  font-weight: 400;
  color: #17252A;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
#journey .experience .experience-item p {
  color: #646565;
  font-weight: 400;
  font-size: 16px;
}
#journey .experience .experience-item.animate {
  opacity: 1;
}
@media (max-width: 980px) {
  #journey {
    padding: 0 0 70px 0;
  }
  #journey .experience:before {
    content: "";
    width: 4px;
    background: #17252A;
    border-radius: 7px;
    position: absolute;
    top: 0;
    height: -o-calc(100% + 70px);
    height: calc(100% + 70px);
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #journey .experience:after {
    content: '';
    width: 45px;
    height: 47px;
    background: url(/static/media/cross.53f294d9.svg) no-repeat center center;
    background-size: cover;
    position: absolute;
    bottom: -155px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #journey .journey-line {
    display: none;
  }
  #journey .main-headings {
    text-indent: 0;
    text-align: center;
    width: 100%;
  }
  #journey .experience-item {
    padding-right: 58% !important;
  }
  #journey .experience-item.right {
    padding-left: 58% !important;
    padding-right: 0 !important;
  }
}
#lets-talk {
  padding: 40px 0 0 0;
  background: #fff;
  margin-top: 70px;
  position: relative;
}
#lets-talk:before {
  content: "";
  width: 100%;
  height: 163px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #17252A;
}
#lets-talk .talk {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
#lets-talk .talk .resume {
  -webkit-flex-basis: 344px;
          flex-basis: 344px;
}
#lets-talk .talk .resume p {
  width: 100%;
  margin-bottom: 45px;
}
#lets-talk .talk .resume .button {
  border: 0;
  padding: 11px 50px 11px 80px;
  background: #3AAFA9;
  color: #fff;
  text-align: center;
  font-family: 'Viga';
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#lets-talk .talk .resume .button:before {
  content: "";
  width: 20px;
  height: 21px;
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url(/static/media/download.83b2cba8.svg) no-repeat center center;
  background-size: cover;
}
#lets-talk .talk .resume .button:hover {
  background: #2d8984;
}
#lets-talk .talk form {
  -webkit-flex-basis: 460px;
          flex-basis: 460px;
  background: #fff;
  box-sizing: border-box;
  padding: 28px 40px 45px 40px;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.12);
}
#lets-talk .talk form .main-headings {
  margin-bottom: 35px;
}
#lets-talk .talk form .form-submit {
  width: 100%;
  background: #3AAFA9;
  height: 51px;
  line-height: 51px;
  text-align: center;
  font-family: 'Viga';
  font-weight: 400;
  outline: none;
  font-size: 18px;
  border: 0;
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
#lets-talk .talk form .form-submit:hover {
  background: #2d8984;
}
#lets-talk .talk form .input-holder {
  position: relative;
  margin-bottom: 40px;
}
#lets-talk .talk form .input-holder input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
#lets-talk .talk form .input-holder .placeholder {
  font-family: 'Inconsolata';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  color: #646565;
  position: absolute;
  left: 15px;
  top: 3px;
  transition: all 0.3s ease-in-out;
}
#lets-talk .talk form .input-holder .placeholder.animate {
  top: -22px;
}
#lets-talk .talk form .input-holder textarea {
  font-family: 'Inconsolata';
  font-weight: bold;
  font-size: 16px;
  color: #000;
  box-sizing: border-box;
  padding: 2px 14px 5px 14px;
  width: 100%;
  min-width: 380px;
  max-width: 380px;
  max-height: 97px;
  min-height: 97px;
  outline: none;
  background: transparent;
  border: 0;
  height: 97px;
  line-height: 18px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  position: relative;
  z-index: 99;
}
#lets-talk .talk form .input-holder input {
  font-family: 'Inconsolata';
  font-weight: bold;
  font-size: 16px;
  color: #646565;
  box-sizing: border-box;
  padding: 2px 14px 5px 14px;
  width: 100%;
  outline: none;
  background: transparent;
  border: 0;
  height: 27px;
  line-height: 25px;
  color: #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  position: relative;
  z-index: 99;
}
#lets-talk .talk form .input-holder input.special {
  height: 97px;
  border-top: 1px solid #000;
}
@media (max-width: 980px) {
  #lets-talk .main-headings {
    text-align: center;
  }
  #lets-talk .talk {
    -webkit-justify-content: center;
            justify-content: center;
  }
  #lets-talk form {
    -webkit-flex-basis: 100% !important;
            flex-basis: 100% !important;
  }
  #lets-talk textarea {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  #lets-talk .resume {
    -webkit-flex-basis: 100% !important;
            flex-basis: 100% !important;
    margin-bottom: 40px;
    text-align: center;
  }
}
#projects {
  background: #fff;
  margin: 150px 0 70px 0;
}
#projects .main-headings {
  position: relative;
  margin-bottom: 40px;
}
#projects .main-headings:after {
  content: "";
  width: 100px;
  height: 4px;
  border-radius: 7px;
  background: #17252A;
  position: absolute;
  top: 50%;
  right: -135px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
#projects .projects-all {
  width: 1250px;
  margin: 0 auto;
  height: auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
#projects .projects-all.show {
  height: auto;
  opacity: 1;
}
#projects .projects-all.hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
#projects .projects-all .slick-dots {
  position: absolute;
  bottom: -40px;
  left: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 999;
  width: 100%;
}
#projects .projects-all .slick-dots li {
  list-style-type: none;
  margin: 0 5px;
  display: inline-block;
}
#projects .projects-all .slick-dots li button {
  width: 15px;
  height: 15px;
  padding: 0;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  background: #fff;
  color: transparent;
  border: 2px solid #3AAFA9;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
#projects .projects-all .slick-dots li.slick-active button {
  background: #3AAFA9;
}
#projects .projects-all .slick-slide {
  padding: 0 5px;
  outline: none;
  box-sizing: border-box;
}
#projects .projects-all .slider-item {
  padding-bottom: 100%;
  height: 0;
  outline: none;
  overflow: hidden;
  position: relative;
  background: #3AAFA9;
}
#projects .projects-all .slider-item .first-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#projects .projects-all .slider-item .second-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.22, 0.16, 1, 0.76);
}
#projects .projects-all .slider-item:hover .second-image {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
#projects .projects {
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  /* Shorthand – you could use ‘flex-direction: column’ and ‘flex-wrap: wrap’ instead */
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
#projects .projects.animate {
  width: 1250px;
}
#projects .projects.animate .project-item {
  margin: 0 5px !important;
  width: 292px !important;
  height: 297px !important;
}
#projects .projects.animate .project-item:nth-child(5),
#projects .projects.animate .project-item:nth-child(6),
#projects .projects.animate .project-item:nth-child(7) {
  opacity: 0;
  height: 0 !important;
  transition: all 0s ease-in-out;
}
@media (min-width: 980px) and (max-width: 1280px) {
  #projects .projects.animate {
    width: 100% !important;
  }
  #projects .projects.animate .project-item {
    width: -o-calc(24% - 10px) !important;
    width: calc(24% - 10px) !important;
  }
  #projects .projects.animate .project-item:nth-child(4) {
    transition: all 0.7s ease-in-out;
  }
}
@media (min-width: 500px) and (max-width: 980px) {
  #projects .projects.animate.show {
    display: -webkit-flex;
    display: flex;
  }
  #projects .projects.animate.hide {
    display: none;
  }
  #projects .projects.animate.animate .project-item {
    margin: 0 0 20px 0 !important;
    height: 237px;
    width: -o-calc(50% - 10px) !important;
    width: calc(50% - 10px) !important;
    transition: all 0s;
  }
  #projects .projects.animate.animate .project-item:nth-child(3) {
    opacity: 0;
    height: 0 !important;
  }
  #projects .projects.animate.animate .project-item:nth-child(4) {
    opacity: 0;
    height: 0 !important;
  }
}
@media (max-width: 500px) {
  #projects .projects.animate .project-item {
    width: 100% !important;
  }
  #projects .projects.animate .project-item:nth-child(2) {
    opacity: 0;
    height: 0 !important;
  }
  #projects .projects.animate .project-item:nth-child(3) {
    opacity: 0;
    height: 0 !important;
  }
  #projects .projects.animate .project-item:nth-child(4) {
    opacity: 0;
    height: 0 !important;
  }
}
#projects .projects.show {
  display: -webkit-flex;
  display: flex;
}
#projects .projects.hide {
  height: 0;
  opacity: 0;
}
#projects .projects .project-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
#projects .projects .project-item .second-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  background-size: cover;
  transition: all 0.3s cubic-bezier(0.22, 0.16, 1, 0.76);
}
#projects .projects .project-item.project-1 {
  height: 470px;
  width: 871px;
  margin-left: -291px;
}
#projects .projects .project-item.project-2 {
  width: 340px;
  height: 237px;
}
#projects .projects .project-item.project-3 {
  width: 340px;
  margin-top: 237px;
  margin-left: -340px;
  height: 463px;
}
#projects .projects .project-item.project-4,
#projects .projects .project-item.project-5 {
  width: 290px;
  margin-top: -230px;
  height: 230px;
}
#projects .projects .project-item.project-6 {
  width: 580px;
  height: 236px;
}
#projects .projects .project-item.view-all {
  width: 340px;
  -webkit-order: 7;
          order: 7;
  height: 236px;
  box-sizing: border-box;
  border: 1px solid #000;
}
#projects .projects .project-item.view-all .button {
  border: 0;
  width: 186px;
  padding: 13px 0;
  background: #3AAFA9;
  color: #fff;
  text-align: center;
  font-family: 'Viga';
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#projects .projects .project-item.view-all .button:hover {
  background: #2d8984;
}
#projects .projects .project-item:hover .second-image {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
@media (max-width: 1280px) {
  #projects .projects-all {
    width: 100% !important;
  }
}
@media (max-width: 980px) {
  #projects {
    margin-bottom: 0;
  }
  #projects .projects {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  #projects .main-headings {
    width: 100%;
    text-align: center;
  }
  #projects .main-headings:after {
    display: none;
  }
  #projects .project-item {
    width: -o-calc(50% - 10px) !important;
    width: calc(50% - 10px) !important;
    margin: 0 0 20px 0 !important;
    height: 237px !important;
  }
  #projects .project-item.view-all {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  #projects .project-item {
    width: 100% !important;
  }
}
#footer {
  background: #17252A;
  padding: 60px 0 80px 0;
}
#footer .footer {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
#footer .footer .socials {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
#footer .footer .socials .footer-icon {
  margin: 0 10px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.22, 0.16, 1, 0.76);
}
#footer .footer .socials .footer-icon:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
#footer .footer .footer-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
#footer .footer .footer-links a {
  padding: 0 13px;
  line-height: 22px;
  margin-bottom: 15px;
  font-family: 'Inconsolata';
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  color: #fff;
}
#footer .footer .footer-links a:after {
  content: "";
  width: 0%;
  height: 4px;
  background: #fff;
  position: absolute;
  left: 50%;
  border-radius: 7px;
  bottom: -10px;
  transition: all 0.3s cubic-bezier(0.22, 0.16, 1, 0.76);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#footer .footer .footer-links a:hover {
  color: #fff;
}
#footer .footer .footer-links a:hover:after {
  width: 60%;
}
.p-right-250 {
  padding-right: 250px;
}
.p-right-150 {
  padding-right: 150px;
}
@media (max-width: 980px) {
  .container {
    width: 100% !important;
  }
}
@media (max-width: 980px) {
  .headerNavigation {
    display: none;
  }
}

