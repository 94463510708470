/* Inconsolata */

/* inconsolata-regular - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inconsolata-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
       url('../fonts/inconsolata-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inconsolata-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inconsolata-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inconsolata-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inconsolata-v16-latin-regular.svg#Inconsolata') format('svg'); /* Legacy iOS */
}
/* inconsolata-700 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inconsolata-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'),
       url('../fonts/inconsolata-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inconsolata-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inconsolata-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inconsolata-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inconsolata-v16-latin-700.svg#Inconsolata') format('svg'); /* Legacy iOS */
}

/* Viga */

/* viga-regular - latin */
@font-face {
  font-family: 'Viga';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/viga-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Viga-Regular'),
       url('../fonts/viga-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/viga-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/viga-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/viga-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/viga-v6-latin-regular.svg#Viga') format('svg'); /* Legacy iOS */
}
